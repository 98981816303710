import React from 'react';
import styled from '@emotion/styled';

import { mqMin } from '@sentry/static-shared/utils/css';
import {
  white,
  ltGreen,
  colorText,
  dkOrange,
} from '@sentry/static-shared/utils/css/colors';
import { defaultDrop } from '@sentry/static-shared/utils/css/shadows';
import { borderRadius } from '@sentry/static-shared/utils/css/constants';
import Layer from '@sentry/static-shared/components/Layer';
import {
  FlexGrid,
  FlexGridCell,
} from '@sentry/static-shared/components/FlexGrid';

import Layout from 'components/layout';
import SEO from 'components/seo';
import { useLocation } from "@reach/router";
import { canonicalURL } from '@sentry/static-shared/utils/CanonicalLink';
import DocHeader from 'components/doc-header';

export const frontmatter = {
  title: 'Benefits',
  subtitle: 'Why Open-Source is Good',
  excerpt: "Open source is the only real option for building a viable software company serving today's developers.",
  order: 0,
};

const BenefitsPage = () => {
  const locationSlug = useLocation();
  const canonicalLink = canonicalURL('open', locationSlug.pathname);
  return (
    <Layout>
      <SEO 
        title={frontmatter.title} 
        description={frontmatter.excerpt}
        canonical={canonicalLink}
      />
      <DocHeader />

      <Layer blackground="white" overlap="jag-right">
        <FlexGrid justifyContent="center">
          <FlexGridCell md={10}>
            <h1>{frontmatter.title}</h1>
            <p>
              People sometimes ask why Sentry is an open source company.
              However, they are typically asking about our business model:
              conversion funnel, product roadmap, monetization strategy. In our
              experience as engineers and as professionals, there is no other
              choice but open source for building a viable software company
              serving modern development cycles.
            </p>
            <p>
              As a mantra, Sentry is an open source company because{' '}
              <em>
                the right to learn and to share what is learned with others
              </em>{' '}
              is fundamental to product growth and relevance.
            </p>
          </FlexGridCell>
        </FlexGrid>
      </Layer>

      <Layer background="light">
        <StyledComparison>
          <StyledComparisonCell>
            <h3>Flexible</h3>
            <p>
              Open source code can be updated locally by any developer and
              changed often (even replaced as needs shift). It’s pliable to each
              user’s specific objectives and use case, and any modifications can
              be made available to the entire community.
            </p>
          </StyledComparisonCell>
          <StyledComparisonDivider />
          <StyledComparisonCell>
            <h3>Locked-in</h3>
            <p>
              When a user is dependant on a vendor, making changes typically
              carries substantial costs, which are passed on to the customer.
              Infrastructure or architecture complexity also usually contribute
              to high switching costs (and high service costs).
            </p>
          </StyledComparisonCell>
        </StyledComparison>
        <StyledComparison>
          <StyledComparisonCell>
            <h3>More Secure</h3>
            <p>
              Software that’s viewable by everyone out in the open inherently
              contains incentives to find and address security and other
              vulnerabilities more quickly. Compliance isn’t beholden to the
              vendor’s ability to hire dedicated engineers.
            </p>
          </StyledComparisonCell>
          <StyledComparisonDivider />
          <StyledComparisonCell>
            <h3>Unauditable</h3>
            <p>
              Security through obscurity often hides issues. If a product is
              closed from public view, those outside the company have little
              idea how many bugs or security issues it may contain.
              Additionally, compliance reviews are very difficult and expensive.
            </p>
          </StyledComparisonCell>
        </StyledComparison>
        <StyledComparison>
          <StyledComparisonCell>
            <h3>Interoperable</h3>
            <p>
              Being able to share and communicate data between systems and
              services is essential to long-term use and user outcomes. Openness
              to integrations is a basic tenet of modern software and key to the
              user experience.
            </p>
          </StyledComparisonCell>
          <StyledComparisonDivider />
          <StyledComparisonCell>
            <h3>Proprietary</h3>
            <p>
              Restrictions on the use of proprietary software are spelled out in
              an end user license agreement, typically prohibiting modifications
              to enable use with certain other technologies (or those upgrades
              are only available for a fee).
            </p>
          </StyledComparisonCell>
        </StyledComparison>
        <StyledComparison>
          <StyledComparisonCell>
            <h3>Community-Oriented</h3>
            <p>
              Open source software is “free like a kitten,” requiring care and
              support from an active community. A common point of reference —
              the code — is a starting point for users to coalesce and determine
              direction and growth.
            </p>
          </StyledComparisonCell>
          <StyledComparisonDivider />
          <StyledComparisonCell>
            <h3>Pay-to-Play</h3>
            <p>
              When companies restrict certain functionality or features to a
              paid version of their software, there is little opportunity to
              build a community, since groups of users have different
              experiences dictated by the vendor.
            </p>
          </StyledComparisonCell>
        </StyledComparison>
        <StyledComparison>
          <StyledComparisonCell>
            <h3>Platform Extensible</h3>
            <p>
              There are few barriers to modifying open source packages for
              different contexts, such as libraries or SDKs for your framework
              of choice. Open source even makes it easier to find community
              partners to work on projects that benefit everyone.
            </p>
          </StyledComparisonCell>
          <StyledComparisonDivider />
          <StyledComparisonCell>
            <h3>Narrowly Focused</h3>
            <p>
              Getting modifications made to accommodate your application or
              systems usually depends on a vendor’s existing roadmap (or your
              ability to pay). Proprietary software rarely has flexibility to
              support emerging (or legacy) needs.
            </p>
          </StyledComparisonCell>
        </StyledComparison>
        <StyledComparison>
          <StyledComparisonCell>
            <h3>Educational</h3>
            <p>
              Open source is not just a try-before-you-buy sales model. It
              encourages users to learn by using the software at scale and lower
              their own barriers to widespread adoption. It also helps with
              staff retention and onboarding new team members.
            </p>
          </StyledComparisonCell>
          <StyledComparisonDivider />
          <StyledComparisonCell>
            <h3>High Friction</h3>
            <p>
              Most vendor-specific software (even open-core and SaaS) not only
              requires payment prior to implementation, but it also limits the
              access users have to learning before adoption, often requiring
              expensive training, certification, and integration.
            </p>
          </StyledComparisonCell>
        </StyledComparison>
      </Layer>
    </Layout>
  );
};

const StyledComparison = styled(FlexGrid)`
  align-items: stretch;
  border-radius: ${borderRadius};
  background-color: ${white};
  box-shadow: ${defaultDrop};
  flex-direction: column;
  margin: 1.5rem 0;
  ${mqMin('sm')} {
    flex-direction: row;
  }
`;
const StyledComparisonCell = styled.div`
  flex: 1;
  margin: 1.5rem 1.5rem 1rem;
  position: relative;
  &:first-of-type::after,
  &:last-of-type::after {
    border-radius: ${borderRadius};
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.75em;
    padding: 0 0.5rem;
    ${mqMin('sm')} {
      font-size: 0.8em;
    }
  }
  &:first-of-type {
    margin-right: 2rem;
    &::after {
      content: 'Open Source';
      background: ${ltGreen};
      color: ${colorText};
    }
  }
  &:last-of-type {
    margin-left: 2rem;
    &::after {
      content: 'Closed Source';
      background: ${dkOrange};
      color: ${white};
    }
  }
`;

const StyledComparisonDivider = styled.div`
  background: #dbd3e9;
  height: 1px;
  width: auto;
  position: relative;
  ${mqMin('sm')} {
    height: auto;
    width: 1px;
  }
  &::before {
    position: absolute;
    display: block;
    content: 'vs';
    background-color: ${white};
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    border: 1px solid #dbd3e9;
    transform: translate(-1.25rem, -1.25rem);
    top: 0;
    left: 50%;
    ${mqMin('sm')} {
      top: 50%;
      left: 0;
    }
  }
`;

export default BenefitsPage;
